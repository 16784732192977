import logo from './logo.svg'

function App() {
  return (
    <div className='App'>
      <img src={logo} className='App-logo' alt='logo' />
      Url Shortener Service
      <br />
      <br />
      <a href='https://github.com/hjck-io'>GitHub hjck-io</a>
    </div>
  )
}

export default App
